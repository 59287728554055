import { Button, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const NftScanBtn = ({ children, darkMode, label, btnClass,  handleClick}) => {
    const { t } = useTranslation();
    return (
        <Button
            variant="contained"
            onClick={handleClick}
            className={styles[btnClass]}
            sx={{
                p: 0,
                marginTop: "5px",
                backgroundColor: `${darkMode ? "#171C26" : "#FFF2F8"}`,
            }}
            >
            <Typography
                color="#ffffff"
                component="span"
                fontSize={20}
                mt={1}
                sx={{
                    textAlign: "right",
                }}
            >
                { children }
            </Typography>
            <Typography variant="body1" component="span">
                { t(label) }
            </Typography>
            <Typography
                color="#ffffff"
                component="span"
                fontSize={20}
                mt={1}
                sx={{
                    textAlign: "right",
                }}
            >
                <LinkIcon />
            </Typography>
        </Button>
    );
}