import React from 'react';
import {
  Button,
  Divider,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { FiSave } from 'react-icons/fi';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import AddToCollectionImage from 'assets/addToCollectionImage.png';

import { useTranslation } from 'react-i18next';

const AddToCollectionModal = ({ open, handleClose, darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <Modal sx={{ zIndex: 500000 }} open={open} onClose={handleClose}>
      <Box
        p={isMobile ? 2 : 4}
        width={isMobile ? '70%' : 350}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto',
          boxShadow: 24,
          cursor: 'move',
          borderRadius: '10px',
        }}
        color={darkMode ? '#fff' : '#121212'}
        bgcolor={darkMode ? '#171c26' : '#fbfbfb'}
      >
        <Stack direction='row' gap={1} alignItems='center'>
          <AddBoxIcon />
          <Box>
            <Typography variant='h6' fontWeight={600}>
              {t('ADD_TO_COLLECTION')}
            </Typography>
            <Divider sx={{ backgroundColor: '#fff' }} />
          </Box>
        </Stack>
        <Typography my={2} variant='subtitle2' color='GrayText'>
          Click and add to a collection of your choice
        </Typography>
        <Box sx={{ height: 300, overflowY: 'auto', mt: 3 }}>
          {[1, 2, 3, 4, 5, 6, 7].map((index) => (
            <Box
              sx={{
                borderBottom: `2px solid ${darkMode ? '#fff' : '#121212'}`,
                py: 1,
                width: '100%',
              }}
              key={index}
            >
              <Stack direction='row' alignItems='center' gap={2}>
                <img src={AddToCollectionImage} alt='Collection Name' />
                <Box>
                  <Typography variant={isMobile ? 'subtitle1' : 'h6'}>
                    Collection Name
                  </Typography>
                  <Typography variant='caption' color='GrayText'>
                    by @creator
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
        <Stack direction='row' justifyContent='flex-end' mt={2} gap={2}>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='pink'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Typography component='span' color='secondary' mt={1}>
              <AiOutlineCloseSquare />
            </Typography>
            <Typography
              variant='body2'
              component='span'
              sx={{
                textTransform: 'capitalize',
                color: `${darkMode ? '#ffffff' : '#040404'}`,
              }}
            >
              {t('CLOSE')}
            </Typography>
          </Button>
          <Button
            color='pink'
            onClick={handleClose}
            variant='contained'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Typography component='span' color='#ffffff' mt={1}>
              <FiSave />
            </Typography>
            <Typography
              variant='body2'
              component='span'
              sx={{
                textTransform: 'capitalize',
                color: '#ffffff',
              }}
            >
              {t('SAVE')}
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddToCollectionModal;
