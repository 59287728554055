import { makeStyles, withStyles } from "@material-ui/core/styles";
import { fontSizes, fontWeights } from './style/fontStyle';
import { constColors } from 'components/Alert/style/color';
import { Tooltip } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    height: "48px",
    position: "sticky",
    top: "72px",
    backgroundColor: constColors.filterContainerColor,
    padding: "20px 0px",
    borderTop: "1px solid #e5e8eb",
    borderBottom: "1px solid #e5e8eb",
    // zIndex: 60,
  },
  subContainer: {
    maxWidth: "1280px",
    margin: "0 auto",
    width: "80%",
    [theme.breakpoints.down(1280)]: {
      padding: "0 20px",
      width: "auto",
    },
  },
  coinDiv: {
    borderRadius: 10,
    width: 150,
    justifyContent: "space-between",
    border: "1px solid rgb(229, 232, 235)",
    padding: 12,
    display: "flex",
    cursor: "pointer",
    color: "rgb(112, 122, 131)",
    background: "rgba(229, 232, 235, 0.25)",
    pointerEvents: "none",
  },
  sellLinkArrow: {
    color: constColors.descColor,
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
  },
  badgeImage: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  badgeImage1: {
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    border: "1px solid #e5e8eb",
    borderRadius: 3,
  },
  collectionName: {
    fontWeight: fontWeights.weight500,
    fontSize: fontSizes.font12,
    color: constColors.descColor,
  },
  nftName: {
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font14,
    color: "#04111d",
  },
  subContainerContent: {
    display: "flex",
    columnGap: "160px",
  },
  listItemForSale: {
    width: "50%",
    [theme.breakpoints.down(1024)]: {
      width: "100%",
    },
  },
  previewContainer: {
    width: "50%",
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  title: {
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font24,
    color: "#04111d",
    margin: "40px 0px",
  },
  leftButton: {
    borderRadius: "10px 0px 0px 10px",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #e5e8eb",
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font16,
    color: constColors.descColor,
    backgroundColor: "#ffffff",
    padding: "12px 20px",
    height: "100%",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
    "&:hover:not([disabled])": {
      transition: "all 0.2s ease 0s",
      boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    },
    "&:disabled": {
      backgroundColor: "#f3fbfe",
      color: "#04111d",
      cursor: "default",
    },
  },

  rightButton: {
    borderLeft: "none",
    borderRadius: "0px 10px 10px 0px",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid #e5e8eb",
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font16,
    color: constColors.descColor,
    backgroundColor: "#ffffff",
    padding: "12px 20px",
    height: "100%",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
    "&:hover:not([disabled])": {
      transition: "all 0.2s ease 0s",
      boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    },
    "&:disabled": {
      backgroundColor: "#f3fbfe",
      color: "#04111d",
      cursor: "default",
    },
  },
  activeBtn: {
    backgroundColor: "#f3fbfe",
    color: "#04111d",
    cursor: "default",
  },
  inputMain: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: `1px solid ${constColors.inputBorder}`,
    display: "flex",
    width: "100%",
    "&:focus-within": {
      boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    },
  },
  inputMain2: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: `1px solid ${constColors.inputBorder}`,
    display: "flex",
    minWidth: "100%",
    marginTop: 16,
    paddingTop: 12,
    "&:focus-within": {
      boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    },
  },
  dateDiv: {
    width: "100%",
    padding: "12px 20px",
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(229, 232, 235)",
    color: "rgb(112, 122, 131)",
    cursor: "pointer",
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 600,
    boxSizing: "border-box",
    "&:hover": {
      boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    },
  },
  calendar: {
    boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
    padding: 20,
    borderRadius: 10,
    maxWidth: "450px",
    marginTop: 10,
  },
  inputInput: {
    height: "48px",
    outline: "none",
    backgroundColor: "transparent",
    border: "none",
    paddingRight: "12px",
    fontSize: fontSizes.font16,
    width: "100%",
    color: constColors.black,
  },
  textArea: {
    minHeight: "70px",
    outline: "none",
    backgroundColor: "transparent",
    border: "none",
    paddingRight: "12px",
    fontSize: fontSizes.font16,
    minWidth: "94%",
    color: constColors.black,
  },
  text3: {
    color: "rgb(112, 122, 131)",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
  },
  moreOptions: {
    color: "#2081e2",
    display: "inline-flex",
    alignItems: "center",
    border: "none",
    background: "transparent",
    fontWeight: fontWeights.weight600,
    "&:hover": {
      color: "#1868b7",
    },
  },
  switchContainer: {
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font16,
    color: "#353840",
    display: "flex",
    justifyContent: "space-between",
  },
  fontType1: {
    fontWeight: fontWeights.weight500,
    fontSize: fontSizes.font12,
    color: constColors.descColor,
  },
  fontType2: {
    fontWeight: fontWeights.weight500,
    fontSize: fontSizes.font14,
    color: constColors.descColor,
  },
  fontType3: {
    fontWeight: fontWeights.weight600,
    fontSize: fontSizes.font16,
    color: "#04111d",
  },
  horizontalDivider: {
    opacity: 0.2,
    color: "#8a939b",
    margin: "30px 0",
  },
  previewSubContainer: {
    paddingBottom: "16px",
    width: "60%",
    position: "sticky",
    top: "162px",
  },
  assetPreview: {
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    minWidth: "280px",
    backgroundColor: "#ffffff",
    border: "1px solid #e5e8eb",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
  },
  assetInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "126px",
  },
  assetCollectionName: {
    color: constColors.descColor,
    fontSize: fontSizes.font12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textDecoration: "none",
  },
  assetSellPreviewFooterName: {
    fontSize: fontSizes.font12,
    lineHeight: "18px",
    letterSpacing: "0.1px",
    color: "#353840",
    fontWeight: fontWeights.weight600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  assetSellPreviewFooterPrice: {
    flex: "1 0 0%",
    maxWidth: "50%",
    alignItems: "flex-end",
    flexDirection: "column",
    display: "flex",
  },
  div8: {
    padding: 24,
  },
  div4: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  img3: {
    width: 150,
    height: 150,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    textAlign: "center",
    margin: "20px 0px",
  },
  text4: {
    fontSize: 16,
    fontWeight: 600,
    padding: 20,
    borderBottom: "1px solid rgb(229, 232, 235)",
  },
  contentDiv2: {
    padding: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    background: "rgb(251, 253, 255)",
    display: "flex",
  },
  div5: {},
  div3: {
    justifyContent: "space-between",
    display: "flex",
    borderTop: "1px solid rgb(229, 232, 235)",
    borderBottom: "1px solid rgb(229, 232, 235)",
    padding: 20,
    marginTop: 20,
    alignItems: "center",
  },
  text1: {
    fontSize: 12,
    color: "rgb(112, 122, 131)",
    fontWeight: 500,
  },
  text2: {
    fontSize: 12,
    color: "rgb(4, 17, 29)",
    fontWeight: 500,
  },
  submitBtn: {
    borderRadius: "9px",
    background: "linear-gradient(82.2deg, #213f97 0%, #213f97 100%)",
    color: constColors.white,
    fontSize: fontSizes.font14,
    fontWeight: fontWeights.weight600,
    height: "37px",
    padding: "12px 20px",
    textTransform: "none",
    cursor: "pointer",
    marginTop: 20,
    alignItem: "center",
    marginBottom: "20px",
    [theme.breakpoints.up(1400)]: {
      fontSize: fontSizes.font24,
      height: "64px",
      padding: "15px 30px",
      borderRadius: "16px",
    },
    "&:disabled": {
      opacity: 0.2,
      color: constColors.white,
    },
  },
  submitBtn1: {
    borderRadius: "9px",
    background: "linear-gradient(82.2deg, #213f97 0%, #213f97 100%)",
    color: constColors.white,
    fontSize: fontSizes.font14,
    fontWeight: fontWeights.weight600,
    cursor: "pointer",
    height: "37px",
    padding: "8px 20px",
    textTransform: "none",
    marginTop: 6,
    border: "none",
    // marginLeft: '24px',
    marginBottom: 20,
    [theme.breakpoints.up(1400)]: {
      fontSize: fontSizes.font18,
      height: "50px",
      padding: "12px 20px",
      borderRadius: "16px",
    },
    "&:disabled": {
      opacity: 0.2,
      color: constColors.white,
    },
  },
  modalContainer: {
    position: "absolute",
    border: "1px solid rgb(229, 232, 235)",
    borderRadius: 10,
    background: "#fff",
    maxWidth: 700,
    margin: "auto",
    width: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  modalHeader: {
    borderBottom: "1px solid rgb(229, 232, 235)",
    padding: 24,
    textAlign: "center",
  },
  contentDiv: {
    border: "1px solid #34c77b",
    borderRadius: 10,
    maxHeigth: 500,
    height: "100%",
    overflowY: "auto",
  },
  div1: {
    padding: 20,
    fontSize: 16,
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    borderBottom: "1px solid rgb(229,232,235)",
  },
  icon1: {
    color: "#34c77b",
    verticalAlign: "middle",
    marginRight: 5,
  },
  icon3: {
    color: "rgb(112, 122, 131)",
    fontWeight: 16,
    padding: 12,
    background: "#fff",
    border: "1px solid rgb(229, 232, 235)",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  icon4: {
    color: "rgb(112, 122, 131)",
    fontWeight: 16,
    padding: 12,
    background: "#fff",
    borderTop: "1px solid rgb(229, 232, 235)",
    borderBottom: "1px solid rgb(229, 232, 235)",
    borderRight: "1px solid rgb(229, 232, 235)",
  },
  icon5: {
    color: "rgb(112, 122, 131)",
    fontWeight: 16,
    padding: 12,
    background: "#fff",
    border: "1px solid rgb(229, 232, 235)",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  icon2: {
    fill: "#34c77b",
    verticalAlign: "middle",
    marginRight: 5,
    marginTop: "-4px",
    "&.MuiCircularProgress-colorPrimary": {
      color: "#34c77b",
    },
  },
  div2: {
    fontWeight: 400,
    fontSize: 16,
    color: "rgb(112,122,131)",
    background: "rgb(251, 253, 255)",
    padding: 20,
    borderBottom: "1px solid rgb(229,232,235)",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    background: "#213f97",
    color: "#fff",
    fontSize: 14,
    padding: 9,
  },
  arrow: {
    color: "#213f97",
  },
}))(Tooltip);
