import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export const FacebookCircularProgress = ({ progress, darkMode }) => {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
            variant="determinate"
            sx={{ color: `${darkMode ? '#4e4e4e' : '#e0e0e0' }` }}
            size={60}
            thickness={4}
            value={100}
        />
        <CircularProgress
            variant="determinate"
            color="success"
            sx={{
                animationDuration: '550ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
            }}
            size={60}
            thickness={4}
            value={progress}
        />
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '30px',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            >
                {`${Math.round(progress)}%`}
            </Typography>
        </Box>
      </Box>
    );
  }