import React from 'react';
import { Grid, Typography, useMediaQuery, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { IoCart } from "react-icons/io5";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import styles from "components/Auction/AuctionCardDetails/AuctionCard.module.css";
import BackgroundWrinkles1 from 'assets/BackgroundWrinkles1.svg';
import BackgroundWrinklesLight from 'assets/backgroundWrinklesLight.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
// import IOSRedSvg from 'assets/appmark/ios-logo-red-5d108d.svg';
import { RiAndroidFill, RiAppleFill, RiAppsFill, RiAppStoreFill, RiDownloadFill, RiMacFill } from 'react-icons/ri';

const AppStore = ({ darkMode }) => {
  const { t } = useTranslation();
  const currentUrl = window.location.origin;
  console.log(currentUrl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleGotoLink = (url) => {
    window.open(url ?? '', '_blank');
  }

  return (
    <>
      <Box
        color={darkMode ? '#ffffff' : '#171c26'}
        sx={{
          position: 'relative',
        }}
      >
        <Box
          style={{
            position: 'fixed',
            height: '100vh',
          }}
        >
          <img
            src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
            alt='Wrinkles'
          />
        </Box>
        <Box sx={!isMobile ? { mt: 11 } : { mt: 2 }}>
          {!isMobile ? (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Typography
                component='div'
                sx={{
                  borderBottom: `2px solid ${darkMode ? '#ffffff' : '#171c26'}`,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='h6'
                  component='p'
                  sx={{ zIndex: 2, cursor: 'pointer' }}
                >
                  {t('APP_DOWNLOAD')}
                </Typography>
                {darkMode && (
                  <Typography
                    variant='h1'
                    component='p'
                    sx={{
                      background:
                        'linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)',
                      borderRadius: '4px',
                      width: '35px',
                      height: '24px',
                      ml: -4,
                    }}
                  ></Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: '3%',
                  zIndex: '1000',
                  width: '75%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box pb={2} ml={6}>
                  <Typography
                    variant='subtitle1'
                    color='secondary'
                    component='div'
                    sx={{
                      borderBottom: `${
                        darkMode ? '2px solid #ffffff' : '1px solid #171c26'
                      }`,
                    }}
                  >
                    {t('APP_DOWNLOAD')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 5, width: '100%' }}>
            <Stack direction="row" alignItems="center" gap={2} sx={{display: 'flex'}}>
              {" "}
              <GradientButtonPrimary
                className={styles.gradientButtonClass}
                variant="contained"
                onClick={() => {
                  handleGotoLink(`${currentUrl}/nftrmarketplace.apk`);
                }}
                sx={{
                    // width: "100%",
                  marginTop: "1rem",
                  flexShrink: 1,
                }}
              >
                <Typography
                    color="#ffffff"
                    component="span"
                    fontSize={20}
                    mt={0.5}
                >
                    <img
                      height={30}
                      width={30}
                      src={`${currentUrl}/appmark/android-logo-white.svg`}
                      alt="android"
                    />
                </Typography>
                <Typography variant="body1" component="span">
                    {t('DOWNLOAD_FOR_ANDROID')}
                </Typography>
                <RiDownloadFill/>
              </GradientButtonPrimary>
              {/* <GradientButtonPrimary
                className={styles.gradientButtonClass}
                variant="contained"
                onClick={() => {
                  handleGotoLink(`${currentUrl}/nftrmarketplace-ios.zip`);
                }}
                sx={{
                    // width: "100%",
                  marginTop: "1rem",
                  flexShrink: 1,
                }}
              >
                <Typography
                    color="#ffffff"
                    component="span"
                    fontSize={20}
                    mt={0.5}
                >
                  <img
                    height={30}
                    width={30}
                    src={`${currentUrl}/appmark/ios-logo-white.svg`}
                    alt="ios"
                  />
                </Typography>
                <Typography variant="body1" component="span">
                    {t('DOWNLOAD_FOR_IOS')}
                </Typography>
                <RiDownloadFill/>
              </GradientButtonPrimary> */}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AppStore;
