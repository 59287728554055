import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { setMessage } from "./message";
import { InfoURL } from "../configs/constant";
import AuthService from "../services/api.slice";

const accessToken = Cookies.get("access_token");

export const register = createAsyncThunk(
  "auth/register",
  async ({ email, full_name, username, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(
        username,
        email,
        full_name,
        password
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      return { ...data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const loginWithRefreshToken = createAsyncThunk(
  "auth/refresh-token",
  async ({ refreshToken }, thunkAPI) => {
    try {
      const data = await AuthService.loginWithRefreshToken(refreshToken);
      return { ...data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async ({ email, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      return { ...data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  loading: false,
  user: {}, // for user object
  accessToken: accessToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
  unclaimedToken: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      Cookies.remove("access_token"); // deletes token from storage
      state.loading = false;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.error = null;
    },
    setCredentials: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
      })
      .addCase(register.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        // state.auth.user = payload.user;
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(loginWithRefreshToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginWithRefreshToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        // state.auth.user = payload.user;
        state.accessToken = payload.accessToken;
        state.refreshToken = payload.refreshToken;
      })
      .addCase(loginWithRefreshToken.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

const { reducer } = authSlice;

export const getNFTPayProWalletBalance = async (user_id) => {
  // console.log(walletAddress);
  try {
    let config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "master-key":
          "jjOOboYoc8Ymc4W4vigKtys2S6hdMs4bcoLyzPdq3fIrkWo2j6wKMqBq7mzhFr5Ji4nZG9CYjGSNyTj8pcNSQI2BqagNxGTgI4eVWVccOTyP723kKviA6uYrCIJlPd89De1hAxYGO1ybp6vefuYgzeNarQPNorsAGI1qdm8DJxCPPPgCwueYiZm6DiUJfTIxQhKehL1SnXAxA8k5ljr2OfcgcZsfCrf9JYju73AwLVQ5xJ1YfmtOOca4FUKWzp07",
      },
    };
    const response = await axios.get(
      `${InfoURL.NFTR_BASE_URL}api/getUserInfoForMarketplace?user_id=${user_id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getNFTRewarDailyAmount = async (user_id) => {
  // console.log(walletAddress);
  try {
    let config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "master-key":
          "jjOOboYoc8Ymc4W4vigKtys2S6hdMs4bcoLyzPdq3fIrkWo2j6wKMqBq7mzhFr5Ji4nZG9CYjGSNyTj8pcNSQI2BqagNxGTgI4eVWVccOTyP723kKviA6uYrCIJlPd89De1hAxYGO1ybp6vefuYgzeNarQPNorsAGI1qdm8DJxCPPPgCwueYiZm6DiUJfTIxQhKehL1SnXAxA8k5ljr2OfcgcZsfCrf9JYju73AwLVQ5xJ1YfmtOOca4FUKWzp07",
      },
    };
    const response = await axios.get(
      `${InfoURL.NFTR_BASE_URL}api/bidsDaily?user_id=${user_id}`,
      config
    );
    return response.data.dailyBids;
  } catch (error) {
    console.log(error);
  }
};

export const getNFTUsedBidAmount = async (user_id) => {
  // console.log(walletAddress);
  try {
    let config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "master-key":
          "jjOOboYoc8Ymc4W4vigKtys2S6hdMs4bcoLyzPdq3fIrkWo2j6wKMqBq7mzhFr5Ji4nZG9CYjGSNyTj8pcNSQI2BqagNxGTgI4eVWVccOTyP723kKviA6uYrCIJlPd89De1hAxYGO1ybp6vefuYgzeNarQPNorsAGI1qdm8DJxCPPPgCwueYiZm6DiUJfTIxQhKehL1SnXAxA8k5ljr2OfcgcZsfCrf9JYju73AwLVQ5xJ1YfmtOOca4FUKWzp07",
      },
    };
    const response = await axios.get(
      `${InfoURL.NFTR_BASE_URL}api/usedBids?user_id=${user_id}`,
      config
    );
    return response.data.UsedBids;
  } catch (error) {
    console.log(error);
  }
};

export const { logout, setCredentials } = authSlice.actions;
export default reducer;
