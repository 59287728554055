import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig } from "wagmi";
import { polygon, polygonMumbai, mainnet, bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

export const { chains, publicClient } = configureChains(
  [polygon, polygonMumbai, mainnet, bsc],
  [publicProvider()]
);

// TODO: export project id to .env
const { connectors } = getDefaultWallets({
  appName: "nftrewards2",
  projectId: "f1928ad9d530c07e14bb339ff5e041b2",
  chains,
});

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
