import {
    Button,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from "@mui/system";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AtIcon from 'assets/Icons/atIcon.svg';
import LockIcon from 'assets/Icons/lockIcon.svg';

export const MobileSignInPage = ({
    darkMode,
    handleSignInUser
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            email: Yup.string().email('Enter a valid email').required('Required'),
            password: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            handleSignInUser(values.email, values.password, navigate, location);
        },
    });
    return(
        <Box px={3} py={3}>
            <Box
                component='form'
                onSubmit={formik.handleSubmit}
                color={darkMode ? '#ffffff' : '#121212'}
                width='100%'
            >
                <Typography variant='h4' fontWeight={500} textAlign='center'>
                    {t('WELCOME_TO_NFT_REWARDS')}
                </Typography>
                <Stack mt={5} direction='column' gap={4}>
                    <Box>
                        <TextField
                            autoComplete='off'
                            id='email'
                            name='email'
                            type='email'
                            variant='outlined'
                            placeholder='example@email.com'
                            label='Email'
                            color='pink'
                            autoFocus
                            fullWidth
                            InputProps={{
                                classes: {
                                input: `${
                                    darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                                }`,
                                },
                                endAdornment: (
                                <InputAdornment position='end'>
                                    {<img src={AtIcon} alt='...' />}
                                </InputAdornment>
                                ),
                            }}
                            {...formik.getFieldProps('email')}
                        />
                            {formik.touched.email && formik.errors.email ? (
                        <Typography
                            mt={1}
                            component='p'
                            color='error'
                            variant='caption'
                        >
                            {formik.errors.email}
                        </Typography>
                        ) : null}
                    </Box>
                    <Box>
                        <TextField
                            id='password'
                            name='password'
                            type='password'
                            variant='outlined'
                            placeholder='Enter your top secret password'
                            label='Password'
                            color='pink'
                            fullWidth
                            InputProps={{
                                classes: {
                                input: `${
                                    darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                                }`,
                                },
                                endAdornment: (
                                <InputAdornment position='end'>
                                    {<img src={LockIcon} alt='...' />}
                                </InputAdornment>
                                ),
                            }}
                            {...formik.getFieldProps('password')}
                        />
                        {formik.touched.password && formik.errors.password ? (
                        <Typography
                            mt={1}
                            component='p'
                            color='error'
                            variant='caption'
                        >
                            {formik.errors.password}
                        </Typography>
                        ) : null}
                    </Box>
                </Stack>
                <Box mt={4}>
                    <Button
                        type='submit'
                        variant='contained'
                        color='blue'
                        fullWidth
                        sx={{ py: 1.5, fontSize: '1rem' }}
                    >
                        {t('LOGIN')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}