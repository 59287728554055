import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useNavigate,} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Grow,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TuneIcon from '@mui/icons-material/Tune';
import HistoryIcon from '@mui/icons-material/History';

import { StyledMenu } from 'components/StyledMenu/StyledMenu';
import CollectionContainer from './CollectionContainer/CollectionContainer';
import { useTranslation } from 'react-i18next';
import { order, defaultFilterValues } from "configs/constant";
const MobileSelectComponent = lazy(() => import("components/Auction/MobileSelectComponent"));
const SelectComponent = lazy(() => import("components/Auction/SelectComponent"));
const SearchInput = lazy(() => import("components/Auction/SearchInput"));

const Collections = ({ darkMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sortOptions, setSorOptions] = useState(t('DATE_CREATED'));
  const [orderOptions, setOrderOptions] = useState(t('NEWEST'));
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderBy] = useState('DESC');
  const [sortBy, setSortBy] = useState('created_at');
  const [orderData, setOrderData] = useState(order[defaultFilterValues?.sort?.value]);
  const user = useSelector((state) => state.auth.user);
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleOrderBtnClicked = (item) => {
    setOrderOptions(t(item.display));
    setOrderBy(item.value);
    handleCloseOrderMenu();
  }

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  const handleChangeSearch = (e) => {
    const {value} = e.target;
    setSearchText(value);
  }

  return (
    <Box
      color={darkMode ? '#ffffff' : '#121212'}
      mt={!isMobile ? 11.5 : 2}
      mb={3}
    >
      {user?.role === 'creator' ? (<Box sx={{ textAlign: 'right' }} mb={3}>
        <Button
          onClick={() => navigate('/collections/create')}
          variant='outlined'
          color='secondary'
          sx={{
            alignItems: 'flex-end'
          }}
        >
          {t('CREATE')}
        </Button>
      </Box>) : null }
      {!isMobile ? (
        <Stack direction='row' alignItems='center' gap={3}>

          <Box width='35%'>
            <Suspense fallback={<div></div>}>
              <SearchInput darkMode={darkMode} handleChangeSearch={handleChangeSearch} isMobile={false}/>
            </Suspense>
          </Box>
          <Box>
            <Button
              onClick={handleOpenSortMenu}
              sx={{
                py: 0.7,
                width: 350,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
              variant='contained'
              color='accent'
              fullWidth
              endIcon={
                <Box color='secondary' mt={1.5}>
                  {openSortMenu ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </Box>
              }
            >
              <Typography
                sx={{ textTransform: 'capitalize' }}
                variant='subtitle1'
                color='secondary'
              >
                {t('SORT_BY')}
              </Typography>
              <Typography sx={{ color: 'gray' }} variant='subtitle1'>
                {sortOptions}
              </Typography>
            </Button>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  width: 330,
                },
              }}
              TransitionComponent={Grow}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions(t('DATE_CREATED'));
                  handleCloseSortMenu();
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('DATE_CREATED')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          <Suspense fallback={<div></div>}>
            <SelectComponent
              handleBtnOnClick={handleOpenOrderMenu}
              handleMenuOnClick={handleOrderBtnClicked}
              handleOnClose={handleCloseOrderMenu}
              label={"ORDER_BY"}
              width={300}
              open={openOrderMenu}
              currentValue={orderOptions}
              anchorEl={orderAnchorEl}
              data={orderData}
            />
          </Suspense>
        </Stack>
      ) : (
        <Stack direction='row' alignItems='center' gap={3}>
          <Box>
            <Suspense fallback={<div></div>}>
              <SearchInput darkMode={darkMode} handleChangeSearch={handleChangeSearch} isMobile={false}/>
            </Suspense>
          </Box>
          {/* Sort */}
          <Box>
            <IconButton
              onClick={handleOpenSortMenu}
              variant='contained'
              fullWidth
            >
              <TuneIcon />
            </IconButton>
            <StyledMenu
              elevation={1}
              PaperProps={{
                style: {
                  borderRadius: '5px',
                  backgroundColor: `${darkMode ? '#040404' : '#fbfbfb'}`,
                },
              }}
              anchorEl={sortAnchorEl}
              open={openSortMenu}
              onClose={handleCloseSortMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                onClick={() => {
                  setSorOptions(t('DATE_CREATED'));
                  handleCloseSortMenu();
                  setSortBy('created_at');
                }}
              >
                <Stack direction='row' gap={1} alignItems='center'>
                  <HistoryIcon sx={{ height: 25, width: 25 }} />
                  <Typography variant='subtitle1' fontWeight={400}>
                    {t('DATE_CREATED')}
                  </Typography>
                </Stack>
              </MenuItem>
            </StyledMenu>
          </Box>
          {/* Order */}
          <Suspense fallback={<div></div>}>
            <MobileSelectComponent
              handleBtnOnClick={handleOpenOrderMenu}
              handleMenuOnClick={handleOrderBtnClicked}
              handleOnClose={handleCloseOrderMenu}
              open={openOrderMenu}
              anchorEl={orderAnchorEl}
              data={orderData}
            />
          </Suspense>
        </Stack>
      )}
      <CollectionContainer darkMode={darkMode} searchText={searchText} sortBy={sortBy} orderBy={orderBy}/>
    </Box>
  );
};

export default Collections;