import {
    Box
} from "@mui/system";
import {
    Typography,
    Avatar,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { MobileTab } from "components/NftCard/NftCardDetails/Component/HistoryView/MobileTab";
import { WebTab } from "components/NftCard/NftCardDetails/Component/HistoryView/WebTab";
import { TabPanel } from "components/NftCard/NftCardDetails/TabPanel";
import { SellStatus, AuctionStatus } from "configs/constant"
import { defaultPngAvatar } from "configs/constant";
import { getLocalDateTime } from "Utils";  

export const HistoryTabPanel = ({darkMode, tabValue, isMobile, bids, history, handleChange }) => {
    const { t } = useTranslation();
    const tabClass = isMobile ? 'tabStylesMobile' : 'tabStyles';
    const operator = (op) => {
        if (op === SellStatus?.ON_SELL) {
            return AuctionStatus?.LISTING;
        }
        return op;
    }
    return (
        <>
            {isMobile && <MobileTab darkMode={darkMode} tabValue={tabValue} handleChange={handleChange}/>}
            {!isMobile && <WebTab  darkMode={darkMode} tabValue={tabValue} handleChange={handleChange}/>}
            <TabPanel value={tabValue} index={0}>
                {/* Global tab styles */}
                <div className={tabClass}>
                    {bids?.map((bid, index) => (
                        <Box mb={2} key={index}>
                            <Typography
                                variant="body1"
                                className={styles.tabBoxContent}
                                component="div"
                            >
                                <Avatar
                                sx={{
                                    border: `2px solid ${
                                    darkMode ? "#ffffff" : "#01D4FA"
                                    }`,
                                }}
                                src={bid?.user?.image ? bid.user.image : defaultPngAvatar}
                                />
                                <Typography variant="body2" component="div">
                                    <span style={{ fontWeight: "bold" }}>{bid.user.username}</span>{" "}
                                        {t("BIDDING_AT")} {getLocalDateTime(bid?.bid_at?.dateTime) + `z${bid?.bid_at?.millisecond}`}
                                </Typography>
                            </Typography>
                        </Box>
                    ))}
                </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <div className={tabClass}>
                    {history.map((bd, index) => (
                        <Box mb={2} key={index}>
                            <Typography
                            component="div"
                            className={styles.tabBoxContent}>
                                <Avatar
                                    sx={{
                                        border: `2px solid ${
                                        darkMode ? "#ffffff" : "#01D4FA"
                                        }`,
                                    }}
                                    src={bd?.maker?.image ? bd.maker.image : defaultPngAvatar}
                                    alt={bd?.maker?.username}
                                />
                                <Typography variant="body2" component="div">
                                    { operator(bd.sell_status) }{" "}
                                    {t("BY")} {" "}
                                    <span style={{ fontWeight: "bold" }}>{bd.maker.username}</span>{" "}
                                    {t("BIDDING_AT")}{" "}{getLocalDateTime(bd?.at)} {" "}
                                </Typography>
                            </Typography>
                        </Box>
                    ))}
                </div>
            </TabPanel>
        </>
    );
}