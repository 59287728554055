import { useEffect } from 'react';
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useFetchData } from "hooks/useFetchData";
import ServerMaintenance from 'assets/server-maintenance.jpg';

export default function NoResponse() {
  // const {data, isLoaded} = useFetchData('statistics', '', {});
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (isLoaded && data) {
  //     navigate('/home');
  //   }
  // }, [isLoaded, data])

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '100vh',
          background: '#040404'
        }}
      >
        <img src={ServerMaintenance} width="100%" height="100%"/>
      </Box>
    );
  }
  