import React from 'react';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AtIcon from 'assets/Icons/atIcon.svg';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

const VerifyEmailPage = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {!isMobile ? (
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          height='100vh'
          px={5}
        >
          <Container>
            <Box color={darkMode ? '#ffffff' : '#121212'} width='100%'>
              <Typography variant='h5' fontWeight={300}>
                {t('FORGOT_PASSWORD')}
              </Typography>
              <Typography mt={1} variant='h3' fontWeight={600}>
                {t('VERIFY_EMAIL')}
              </Typography>
              <Typography
                mt={1}
                variant='subtitle1'
                fontSize={18}
                color='GrayText'
              >
                {t('ENTER_REGISTERED_EMAIL_ADDRESS')}
              </Typography>
              <Stack mt={5} direction='column' gap={4}>
                <TextField
                  InputProps={{
                    classes: {
                      input: `${
                        darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                      }`,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        {<img src={AtIcon} alt='...' />}
                      </InputAdornment>
                    ),
                  }}
                  variant='outlined'
                  placeholder='example@email.com'
                  label='Email'
                  color='pink'
                  autoFocus
                  fullWidth
                />
              </Stack>
              <Box mt={4}>
                <Button
                  onClick={() => navigate('/authentication/set-password')}
                  variant='contained'
                  color='blue'
                  fullWidth
                  sx={{ py: 1.5, fontSize: '1rem' }}
                >
                  {t('SUBMIT')}
                </Button>
              </Box>
              <Box mt={5}>
                <Typography variant='body1' textAlign='center'>
                  Having doubts?{' '}
                  <Typography
                    onClick={() => window.history.back()}
                    color='blue'
                    component='span'
                    sx={{
                      textDecoration: 'underline',
                      color: '#01D4FA',
                      cursor: 'pointer',
                    }}
                  >
                    {t('GO_BACK')}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Stack>
      ) : (
        <Box px={3} py={3} mt={5}>
          <Box color={darkMode ? '#ffffff' : '#121212'} width='100%'>
            <Typography variant='h6' fontWeight={300}>
              {t('FORGOT_PASSWORD')}
            </Typography>
            <Typography mt={1} variant='h4' fontWeight={600}>
              {t('VERIFY_EMAIL')}
            </Typography>
            <Typography
              mt={1}
              variant='subtitle1'
              fontSize={18}
              color='GrayText'
            >
              {t('ENTER_REGISTERED_EMAIL_ADDRESS')}
            </Typography>
            <Stack mt={5} direction='column' gap={4}>
              <TextField
                TextField
                InputProps={{
                  classes: {
                    input: `${
                      darkMode ? 'inputFieldAuth' : 'inputFieldAuthLight'
                    }`,
                  },
                  endAdornment: (
                    <InputAdornment position='end'>
                      {<img src={AtIcon} alt='...' />}
                    </InputAdornment>
                  ),
                }}
                variant='outlined'
                placeholder='example@email.com'
                label='Email'
                color='pink'
                autoFocus
                fullWidth
              />
            </Stack>
            <Box mt={4}>
              <Button
                onClick={() => navigate('/authentication/set-password')}
                variant='contained'
                color='blue'
                fullWidth
                sx={{ py: 1.5, fontSize: '1rem' }}
              >
                {t('SUBMIT')}
              </Button>
            </Box>
            <Box mt={5}>
              <Typography variant='body1' textAlign='center'>
                Having doubts?{' '}
                <Typography
                  onClick={() => window.history.back()}
                  color='blue'
                  component='span'
                  sx={{
                    textDecoration: 'underline',
                    color: '#01D4FA',
                    cursor: 'pointer',
                  }}
                >
                  {t('GO_BACK')}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default VerifyEmailPage;
