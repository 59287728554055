import React, { useEffect, memo } from "react";

import {
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import IndividualnftCard from "./IndividualnftCard";

const areEqualProp = (prevProps, nextProps) => {
  return prevProps.darkMode === nextProps.darkMode && prevProps.mode === nextProps.mode;
  ;
}
const IndividualNft = ({ darkMode, mode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box mt={!isMobile ? 10 : 2}>
        <IndividualnftCard darkMode={darkMode} mode1={mode} />
      </Box>
    </>
  );
};


export default memo(IndividualNft, areEqualProp);
