import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  useMediaQuery,
  Stack,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { Chains } from "configs/constant";
import useQuery from "hooks/useQuery";
import { sort, order, getAuctionStatus, defaultFilterValues } from "configs/constant";
import { AuctionBtnGroup } from "./AuctionBtnGroup"
import BackgroundWrinkles1 from "assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "assets/backgroundWrinklesLight.svg";
const MobileSelectComponent = lazy(() => import("components/Auction/MobileSelectComponent"));
const SelectComponent = lazy(() => import("components/Auction/SelectComponent"));
const SearchInput = lazy(() => import("components/Auction/SearchInput"));
const AuctionCards = lazy(() => import("./AuctionCards"));
const Auction = ({ darkMode }) => {
  const { t } = useTranslation();
  let query = useQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [blockchain, setBlockchain] = useState(defaultFilterValues?.blockchain?.value);
  const [orderData, setOrderData] = useState(order[defaultFilterValues?.sort?.value]);
  const [sortOptions, setSorOptions] = useState(defaultFilterValues?.sort?.display);
  const [orderOptions, setOrderOptions] = useState(defaultFilterValues?.order?.display);
  const [chainAnchorEl, setChainAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [currentTab, setCurrentTab] = useState(getAuctionStatus(query.get('type')));
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState(defaultFilterValues?.sort?.value);
  const [orderBy, setOrderBy] = useState(defaultFilterValues?.order?.value);

  const openChainMenu = Boolean(chainAnchorEl);
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDetails = (collectionId, tokenId) => {
    navigate(`/auction/${collectionId}/${tokenId}`);
  };

  const handleAuctionTabClicked = (index) => {
    navigate(`/auction?type=${index === 1 ? "live" : index === 2 ? "upcoming" : "fixed"}`);
    setCurrentTab(index);
  }

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };
  const handleOpenChainMenu = (event) => {
    setChainAnchorEl(event.currentTarget);
  };

  const handleCloseChainMenu = () => {
    setChainAnchorEl(null);
  };
  const handleOpenOrderMenu = (event) => {
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  const handleChainBtnClicked = (item) => {
    setBlockchain(item?.value);
    handleCloseChainMenu();
  }

  const handleSortBtnClicked = (item) => {
    const firstOrderDatum = order[item.value]?.length ? order[item.value][0] : {};
    setSorOptions(t(item.display));
    setOrderData(order[item.value]);
    setOrderOptions(firstOrderDatum?.display);
    setOrderBy(firstOrderDatum?.value);
    setSortBy(item.value);
    handleCloseSortMenu();
  }

  const handleOrderBtnClicked = (item) => {
    setOrderOptions(t(item.display));
    setOrderBy(item.value);
    handleCloseOrderMenu();
  }

  const handleChangedSearch = (e)  => {
    const {value} = e.target;
    setSearchText(value);
  }

  return (
    <Box
      sx={{
        position: "relative",
      }}
      color={darkMode ? "#ffffff" : "#121212"}
    >
      <Box
        style={{
          position: "fixed",
          height: "100vh",
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt="Wrinkles"
        />
      </Box>
      <Box sx={{ width: "100%", mt: 11.5, mb: 3 }}>
        {!isMobile && (
          <Stack direction="row" alignItems="center" gap={4}>
            <Box width="35%">
              <Suspense fallback={<div></div>}>
                <SearchInput darkMode={darkMode} handleChangeSearch={handleChangedSearch} isMobile={false}/>
              </Suspense>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
              {/*chain*/}
                <Suspense fallback={<div></div>}>
                  <SelectComponent
                    handleBtnOnClick={handleOpenChainMenu}
                    handleMenuOnClick={handleChainBtnClicked}
                    handleOnClose={handleCloseChainMenu}
                    label={"BLOCKCHAIN"}
                    open={openChainMenu}
                    currentValue={blockchain}
                    anchorEl={chainAnchorEl}
                    data={Chains}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={4}>
              {/* sort */}
                <Suspense fallback={<div></div>}>
                  <SelectComponent
                    handleBtnOnClick={handleOpenSortMenu}
                    handleMenuOnClick={handleSortBtnClicked}
                    handleOnClose={handleCloseSortMenu}
                    label={"SORT_BY"}
                    open={openSortMenu}
                    currentValue={sortOptions}
                    anchorEl={sortAnchorEl}
                    data={sort}
                  />
                </Suspense>
              </Grid>
              <Grid item xs={4}>
              {/* Order */}
                <Suspense fallback={<div></div>}>
                  <SelectComponent
                    handleBtnOnClick={handleOpenOrderMenu}
                    handleMenuOnClick={handleOrderBtnClicked}
                    handleOnClose={handleCloseOrderMenu}
                    label={"ORDER_BY"}
                    open={openOrderMenu}
                    currentValue={orderOptions}
                    anchorEl={orderAnchorEl}
                    data={orderData}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
      <Box>
        <AuctionBtnGroup
          darkMode={darkMode}
          currentTab={currentTab}
          isMobile={isMobile}
          handleAuctionTabClicked={handleAuctionTabClicked}
        />
        <Box>
          {isMobile && (
            <Stack
              direction="row"
              alignItems="center"
              gap={3}
              sx={{ mt: "3rem" }}
            >
              <Suspense fallback={<div></div>}>
                <SearchInput darkMode={darkMode} handleChangeSearch={handleChangedSearch} isMobile={true}/>
              </Suspense>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {/*chain*/}
                  <Suspense fallback={<div></div>}>
                    <MobileSelectComponent
                      handleBtnOnClick={handleOpenChainMenu}
                      handleMenuOnClick={handleChainBtnClicked}
                      handleOnClose={handleCloseChainMenu}
                      open={openChainMenu}
                      anchorEl={chainAnchorEl}
                      data={Chains}
                    />
                  </Suspense>
                </Grid>
                <Grid item xs={4}>
                  {/* Sort */}
                  <Suspense fallback={<div></div>}>
                    <MobileSelectComponent
                      handleBtnOnClick={handleOpenSortMenu}
                      handleMenuOnClick={handleSortBtnClicked}
                      handleOnClose={handleCloseSortMenu}
                      open={openSortMenu}
                      anchorEl={sortAnchorEl}
                      data={sort}
                    />
                  </Suspense>
                </Grid>
                <Grid item xs={4}>
                  {/* Order */}
                  <Suspense fallback={<div></div>}>
                    <MobileSelectComponent
                      handleBtnOnClick={handleOpenOrderMenu}
                      handleMenuOnClick={handleOrderBtnClicked}
                      handleOnClose={handleCloseOrderMenu}
                      open={openOrderMenu}
                      anchorEl={orderAnchorEl}
                      data={orderData}
                    />
                  </Suspense>
                </Grid>
              </Grid>
            </Stack>
          )}
        </Box>
        <Box sx={{ mt: "3rem" }}>
          <Suspense fallback={<div></div>}>
            <AuctionCards
              handleDetails={handleDetails}
              darkMode={darkMode}
              blockchain={blockchain}
              orderBy={orderBy}
              sortBy={sortBy}
              queryName={query.get("type")}
              auctionStatus={currentTab}
              searchText={searchText}
            />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default Auction;
