import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { FiSave } from "react-icons/fi";

import BackgroundWrinkles1 from "assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "assets/backgroundWrinklesLight.svg";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import SwitchComponent from "../CreateAssets/SwitchComponent";
import { useApolloQuery, useApolloMutation } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
import {
  getNFTPayProWalletBalance,
  getNFTRewarDailyAmount,
  getNFTUsedBidAmount,
} from "slices/auth.slice";
import { useCheckedLogin } from "hooks/useCheckedLogin";
import {
  checkEnable,
  enableMint,
  updateProfitPercent,
  updateWalletAddress,
} from "services/api.slice";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";

const MyWalletInfoContainer = ({ darkMode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data, refetch } = useApolloQuery("userInfo", {});
  const { handleMutation } = useApolloMutation("setAutoBid");
  const { enqueueSnackbar } = useSnackbar();

  useMount(refetch);
  const handleChange = async () => {
    await handleMutation({ user_id: parseInt(userInfo.id) });
  };

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };
  const [check, setCheck] = useState(false);

  const handleChangeRole = async () => {
    const result = await enableMint(!check);
    setCheck(!check);
    if (!result) {
      showSnackMessage("Failed to enable nft mint", "error");
    }
  };

  useCheckedLogin();
  const [userInfo, setUserInfo] = useState({});
  const [balance, setBalance] = useState(0);
  const [normalBids, setNormalBids] = useState(0);
  const [dailyAmount, setDailyAmount] = useState(0);
  const [todayUsed, setTodayUsed] = useState(0);
  const [wallet, setWallet] = useState("");
  const [percent, setPercent] = useState(17);

  useEffect(() => {
    if (data?.userInfo) {
      setUserInfo(data?.userInfo);
      if (data?.userInfo?.wallet_address) {
        setWallet(data?.userInfo?.wallet_address);
      }
    }
  }, [data]);

  useEffect(() => {
    async function fetchWalletInfo() {
      if (userInfo) {
        try {
          const totalBalance = await getNFTPayProWalletBalance(userInfo?.id);
          const totalDailyBids = await getNFTRewarDailyAmount(userInfo?.id);
          const totalUsedAmount = await getNFTUsedBidAmount(userInfo?.id);
          const settingInfo = await checkEnable();
          setCheck(settingInfo.status);
          setPercent(settingInfo.profitPercent);
          setBalance(totalBalance?.available_balance ?? 0);
          setNormalBids(totalBalance?.normal_bids ?? 0);
          setDailyAmount(totalDailyBids ?? 0);
          setTodayUsed(totalUsedAmount ?? 0);
        } catch (e) {}
      }
    }

    fetchWalletInfo();
  }, [userInfo]);

  const handleSave = async () => {
    if (!wallet) {
      showSnackMessage("Please input wallet address", "error");
    } else {
      const data = {
        userId: userInfo?.id,
        walletAddress: wallet,
      };
      const response = await updateWalletAddress(data);
      if (response) {
        showSnackMessage("Updated wallet address successfully", "success");
      } else {
        showSnackMessage("Failed to update wallet addresss", "error");
      }
    }
  };

  const handlePercentageSave = async () => {
    if (!percent) {
      showSnackMessage("Please input profit percentage exactly", "error");
    } else {
      const data = {
        percent: percent.toString(),
      };
      const response = await updateProfitPercent(data);
      if (response) {
        showSnackMessage("Updated profit percentage successfully", "success");
      } else {
        showSnackMessage("Failed to update profit percentage", "error");
      }
    }
  };

  return (
    <>
      <Box
        color={darkMode ? "#ffffff" : "#171c26"}
        sx={{
          position: "relative",
        }}
      >
        <Box
          style={{
            position: "fixed",
            height: "100vh",
          }}
        >
          <img
            src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
            alt="Wrinkles"
          />
        </Box>
        <Box sx={!isMobile ? { mt: 11 } : { mt: 2 }}>
          {!isMobile ? (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                gap: 4,
                alignItems: "center",
              }}
            >
              <Typography
                component="div"
                sx={{
                  borderBottom: `2px solid ${darkMode ? "#ffffff" : "#171c26"}`,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ zIndex: 2, cursor: "pointer" }}
                >
                  {t("MY_WALLET_INFO")}
                </Typography>
                {darkMode && (
                  <Typography
                    variant="h1"
                    component="p"
                    sx={{
                      background:
                        "linear-gradient(91.95deg, #2175D7 1.75%, #5ACDFE 98.13%)",
                      borderRadius: "4px",
                      width: "35px",
                      height: "24px",
                      ml: -4,
                    }}
                  ></Typography>
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "3%",
                  zIndex: "10000",
                  width: "75%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box pb={2} ml={6}>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    component="div"
                    sx={{
                      borderBottom: `${
                        darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                      }`,
                    }}
                  >
                    {t("MY_WALLET_INFO")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#171c26" : "#fff2f8"}`,
              borderRadius: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
              mt: 5,
            }}
          >
            <Grid container>
              <Card sx={{ maxWidth: 500 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {t("TOTAL_BALANCE")}
                      </Typography>
                    </Grid> */}
                    {/* <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {balance}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {t("BID_AMOUNT")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {normalBids}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {t("DAILY_BID_AMOUNT")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {dailyAmount}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {t("TODAY_USED_AMOUNT")}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography sx={{ textAlign: "right" }}>
                        {todayUsed}
                      </Typography>
                    </Grid>
                    {userInfo?.role == "creator" && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{ textAlign: "right" }}>
                            <b>
                              <span>{t("CHANGE_ROLE")}</span>
                            </b>
                          </Typography>
                        </Grid>
                        {/* <Typography component="h6">
                    {t("AUTO_BID_SYSTEM_DESCRIPTION")}
                  </Typography> */}
                        {/* <SwitchComponent
                    darkModel={darkMode}
                    name="is_auto_bid"
                    handleChange={handleChange}
                    autoChecked={userInfo.is_auto_bid === 1}
                  /> */}
                        <Grid item xs={6}>
                          <div
                            style={{
                              textAlign: "right",
                              marginTop: -10,
                              marginRight: -10,
                            }}
                          >
                            <Switch
                              name="change_role"
                              // classes={switchStyles}
                              color="success"
                              checked={check}
                              onChange={handleChangeRole}
                            ></Switch>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid item xs={8}>
                      <TextField
                        className={darkMode && "inputField"}
                        type="text"
                        placeholder={t("WALLET_ADDRESS")}
                        name="wallet_address"
                        color="secondary"
                        style={{
                          fontSize: "14px",
                          color: `${darkMode ? "#ffffff" : "#040404"}`,
                          backgroundColor: `${
                            darkMode ? "#040404" : "#ffffff"
                          }`,
                          width: "100%",
                          marginLeft: 15,
                        }}
                        label={"wallet"}
                        value={wallet}
                        onChange={(e) => setWallet(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <GradientButtonPrimary
                        onClick={() => handleSave()}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: 5,
                        }}
                      >
                        <Typography component="span" color="#ffffff" mt={1}>
                          <FiSave />
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{
                            textTransform: "capitalize",
                            color: "#ffffff",
                          }}
                        >
                          {t("SAVE")}
                        </Typography>
                      </GradientButtonPrimary>
                    </Grid>
                  </Grid>
                  {userInfo?.role === "creator" && (
                    <Grid container style={{ marginTop: 10 }}>
                      <Grid item xs={8}>
                        <TextField
                          className={darkMode && "inputField"}
                          type="number"
                          placeholder={t("COMPANY_PROFIT_PERCENTAGE")}
                          name="company_profit_percent"
                          color="secondary"
                          style={{
                            fontSize: "14px",
                            color: `${darkMode ? "#ffffff" : "#040404"}`,
                            backgroundColor: `${
                              darkMode ? "#040404" : "#ffffff"
                            }`,
                            width: "100%",
                            marginLeft: 15,
                          }}
                          label={"Company Profit Percentage"}
                          value={percent}
                          onChange={(e) => setPercent(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <GradientButtonPrimary
                          onClick={() => handlePercentageSave()}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: 5,
                          }}
                        >
                          <Typography component="span" color="#ffffff" mt={1}>
                            <FiSave />
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              textTransform: "capitalize",
                              color: "#ffffff",
                            }}
                          >
                            {t("SAVE")}
                          </Typography>
                        </GradientButtonPrimary>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyWalletInfoContainer;
