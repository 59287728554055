import { useTheme } from "@emotion/react";
import {lazy, Suspense} from 'react';
import { Container, useMediaQuery } from "@mui/material";
import React from "react";

const Navigation = lazy(() => import("../Navigation/Navigation"));
const Footer = lazy(() => import("../Footer/Footer"));
const SideBar = lazy(() => import("../SideBar/SideBar"));

const Layout = ({ children, darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Container
        sx={
          darkMode
            ? { backgroundColor: "#040404" }
            : { backgroundColor: "#ffffff" }
        }
      >
        <Suspense fallback={<div></div>}>
          <Navigation darkMode={darkMode} />
        </Suspense>
        <Suspense fallback={<div></div>}>
          <SideBar darkMode={darkMode} />
        </Suspense>
        <Container sx={isMobile ? { py: 10 } : { mt: -10 }}>
          {children}
        </Container>
      </Container>
      <Suspense fallback={<div></div>}>
        {!isMobile && <Footer darkMode={darkMode} />}
      </Suspense>
    </React.Fragment>
  );
};

export default Layout;
