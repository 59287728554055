import { Box } from "@mui/system"
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const NormalInfoDisplay = ({ darkMode, label, value }) => {
    const { t } = useTranslation();
    return (
        <Box className={styles.nftDisplayDetailsContent}>
            <Typography
                variant="subtitle1"
                gutterBottom
                color="gray"
            >
                {t(label)}
            </Typography>
            <Typography
                variant="h6"
                component="p"
                color={darkMode ? "#FFFFFF" : "#121212"}
                fontWeight={500}
            >
                { value }
            </Typography>
        </Box>
    );
}