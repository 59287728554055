import { Container, Typography } from "@mui/material";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "components/Auction/AuctionCardDetails/AuctionCardDetails.module.css";
import { subscribeToBidAdded } from "slices/graphql.slice";
import { isUnClaimed } from "services/api.slice";
import { useMount } from "hooks/useMount";
import { useApolloQuery } from "hooks/useApolloQuery";
import { isLiveAuction } from "Utils";

const AuctionCardShow = lazy(() => import("./AuctionCardShow"));

const AuctionCardDetails = ({ darkMode }) => {
  const { collectionId, tokenId } = useParams(); // Read from url
  const user = useSelector((state) => state.auth.user);
  const [currentUser, setCurrentUser] = useState(user);
  const [auctionNftWork, setAuctionNftWork] = useState();
  const [isUnClaimedStatus, setIsUnClaimedStatus] = useState(false);
  const dispatch = useDispatch();
  const params = {
    collection_id: parseInt(collectionId),
    token_id: parseInt(tokenId),
    user: currentUser?.id ?? 0,
  };
  const addedBid = useSelector((state) => state.nft.addedBid);
  const auctionFinishedInfo = useSelector(
    (state) => state.nft.auctionFinishedInfo
  );
  const { data: nftCardInfomation, refetch } = useApolloQuery(
    "auction",
    params
  );

  useMount(refetch);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  useEffect(async () => {
    if (nftCardInfomation && nftCardInfomation?.nftcardFromTokenId) {
      const nft = nftCardInfomation?.nftcardFromTokenId;
      setAuctionNftWork(nft);
      if (isLiveAuction(nft)) {
        dispatch(subscribeToBidAdded(`${collectionId}-${tokenId}`));
      }
    }
  }, [collectionId, dispatch, nftCardInfomation, tokenId]);

  useEffect(async () => {
    if (user.id) {
      const unclaimed = await isUnClaimed(user.id);
      setIsUnClaimedStatus(unclaimed);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (addedBid && auctionNftWork?.bids) {
      const bidHistory = [addedBid, ...auctionNftWork?.bids];
      setAuctionNftWork((prevState) => ({
        ...prevState,
        last_price: addedBid?.nft?.last_price,
        auction_end_at: addedBid?.nft?.auction_end_at,
        sell: {
          ...prevState?.sell,
          auction_end_at: addedBid?.nft?.auction_end_at,
        },
        bids: bidHistory,
      }));
    }
  }, [addedBid]);

  useEffect(() => {
    if (auctionFinishedInfo) {
      setAuctionNftWork((prevState) => ({
        ...prevState,
        enableClaim:
          parseInt(auctionFinishedInfo?.auction_winner?.id) ===
          parseInt(user?.id),
        auction_status: auctionFinishedInfo?.auction_status,
        auction_winner: auctionFinishedInfo?.auction_winner,
      }));
    }
  }, [auctionFinishedInfo, user?.id]);
  return (
    <>
      <Container className={styles.auctionCardContainer}>
        <Typography variant="h3" color="secondary.main" component="div">
          {auctionNftWork && auctionNftWork.id ? (
            <Suspense fallback={<div></div>}>
              <AuctionCardShow
                darkmode={darkMode}
                key={auctionNftWork.id}
                fa={auctionNftWork}
                nftId={auctionNftWork.id}
                isUnClaimedStatus={isUnClaimedStatus}
              />
            </Suspense>
          ) : null}
        </Typography>
      </Container>
    </>
  );
};

export default AuctionCardDetails;
