import {
  TextField,
  MenuItem,
} from '@mui/material';
import { categories } from 'configs/constant';
import { useTranslation } from 'react-i18next';

const CategorySelect = ({ darkMode, value, onChange }) => {

  const { t } = useTranslation();

  return (
    <TextField
        id="outlined-select-currency"
        select
        label="Category"
        name="category"
        color='secondary'
        onChange={onChange}
        defaultValue={value??categories[0].cate}
        value={value??categories[0].cate}
        helperText="Please select your currency"
        >
        {categories.map((option) => (
            <MenuItem key={option.id} value={option.type}>
            {t(option.text)}
            </MenuItem>
        ))}
    </TextField>
  );
};

export default CategorySelect;