import { Box } from "@mui/system";
import IPFSIcon from "assets/Icons/darkUIIcons/ipfs.svg";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { InfoURL, chainIcons } from "configs/constant";
import { NftScanBtn } from "components/NftCard/NftCardDetails/Component/NftScanBtn";

export const NftResource = ({ isMobile, darkMode, tokenURI, contract, tokenId, blockchain }) => {
    const handleClickPolygonScan = () => {
        window.open(`${InfoURL.POLYGON_URL}${contract}/${tokenId}`, '_blank');
    }

    const detailsClass = isMobile ? 'nftDisplayDetailsMobile' : 'nftDisplayDetails';
    const detailsContentClass = isMobile ? 'nftDisplayDetailsContentMob' : 'nftDisplayDetailsContent';
    const detailsBtnClass = isMobile ? 'nftDisplayDetailsButtonMob' : 'nftDisplayDetailsButton';

    return (
        <Box
            className={styles[detailsClass]}
            sx={{
                mt: 2,
            }}
        >
            <Box className={styles[detailsContentClass]}>
                <NftScanBtn
                    darkMode={darkMode}
                    label={"VIEW_ON_IPFS"}
                    btnClass={detailsBtnClass}
                    handleClick={() => {
                        window.open(tokenURI, "_blank");
                    }
                }>
                    <img
                        src={IPFSIcon}
                        alt="IPFS Icon"
                        width="20"
                        height={"20"}
                    />
                </NftScanBtn>
                <NftScanBtn
                    darkMode={darkMode}
                    label={isMobile ? "VIEW_POLYGON_SCAN" : "POLYGON_SCAN"}
                    btnClass={detailsBtnClass}
                    handleClick={handleClickPolygonScan}>
                    <img
                        src={chainIcons[blockchain]?.logo}
                        alt="Polygon Icon"
                        width="20"
                        height={"20"}
                    />
                </NftScanBtn>
            </Box>
        </Box>
    );
}