import React, { useEffect, useState, lazy, Suspense } from "react";
import { Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { useApolloQuery } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
import styles from "components/NftCard/NftCardDetails/NftCardDetails.module.css";
const NftWork = lazy(() => import("./NftWork"));

const NftCardDetails = ({ darkMode }) => {
  const [nftData, setNftData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const [currentUser, setCurrentUser] = useState(user);
  const { collectionId, tokenId } = useParams(); // Read from url

  const params = {
    collection_id: parseInt(collectionId),
    token_id: parseInt(tokenId),
    user: currentUser?.id ?? 0,
  };

  const { data: nftCardInfomation, refetch } = useApolloQuery("token", params);
  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);

  useMount(refetch);
  useEffect(() => {
    if (nftCardInfomation) {
      setIsLoading(false);
      const nft = nftCardInfomation.nftcardFromTokenId;
      setNftData(nft);
    }
  }, [nftCardInfomation]);

  return (
    <>
      <Loader
        isLoading={isLoading}
        message={"Loading..."}
        darkMode={darkMode}
      />
      {nftData && (
        <Container className={styles.nftCardDetailsContainer}>
          <Typography variant="h3" color="secondary.main" component="div">
            <Suspense fallback={<div></div>}>
              <NftWork darkMode={darkMode} fa={nftData} nftId={nftData.id} />
            </Suspense>
          </Typography>
        </Container>
      )}
      {/*  */}
    </>
  );
};

export default NftCardDetails;
