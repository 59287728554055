import React from 'react';
import {
  Button,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';

import { useTranslation } from 'react-i18next';
const DeleteFromCollection = ({ open, handleClose, darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  return (
    <Modal sx={{ zIndex: 500000 }} open={open} onClose={handleClose}>
      <Box
        p={isMobile ? 2 : 4}
        width={isMobile ? '70%' : 400}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto',
          boxShadow: 24,
          cursor: 'move',
          borderRadius: '10px',
          textAlign: 'center',
        }}
        color={darkMode ? '#fff' : '#121212'}
        bgcolor={darkMode ? '#171c26' : '#fbfbfb'}
      >
        <Typography variant='h5' mb={1} color='secondary' fontWeight={700}>
          {t('REMOVE_FROM_SELL')}
        </Typography>
        <Typography
          mt={2}
          variant='body2'
          fontWeight={300}
          color='secondary'
          lineHeight={2}
        >
          {t('DELETE_CONFIRMATION_POPUP')}
        </Typography>
        <Stack
          mt={2}
          direction='row'
          justifyContent='center'
          alignItems='center'
          gap={3}
        >
          <Button onClick={handleClose} variant='outlined' color='pink'>
            {t('CANCEL')}
          </Button>
          <Button onClick={handleClose} variant='contained' color='pink'>
            {t('CONFIRM')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DeleteFromCollection;
