import {
    Grid,
    Stack,
    Typography,
    useMediaQuery,
  } from "@mui/material";
  import { useTheme } from "@emotion/react";
  import { Box } from "@mui/system";
  import { useSnackbar } from 'notistack';
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import {  useNavigate  } from 'react-router-dom';
  import { useSelector } from "react-redux";
  // Icons
  import { MdAddToPhotos } from "react-icons/md";
  import Loader from 'components/Loader/Loader';
  import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
  import UploadComponent from "Pages/CreateAssets/UploadComponent";
  import InputComponent from "Pages/CreateAssets/InputComponent";
  import { pinFileToIPFS } from 'Utils';
  import { PINATA_ASSET_BASE_URL } from 'configs/constant';
  import AlertDialog from "components/Alert/AlertDialog";
  import { useApolloMutation } from "hooks/useApolloQuery";
  import { useCheckedLogin } from "hooks/useCheckedLogin";
  import { PAYABLE_CHAIN } from "configs/constant";
  import SelectComponent from "Pages/CreateAssets/components/SelectComponent";

const CreateCryptos = ({ darkMode }) => {
    const loginedUser = useSelector((state) => state.auth.user);
    const { handleMutation } = useApolloMutation('createCrypto');
    let navigate = useNavigate();
    useCheckedLogin();
    useEffect(() => {
        if (!!loginedUser?.role && loginedUser?.role !== 'payment-creator')
        navigate('/home');
    }, [loginedUser, navigate]);

    const [image, setImage] = useState(null);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isCreatingToken, setIsCreatingToken] = useState(false);
    const [tokenItem, setTokenItem] = useState({});
    const [loadMsg, setLoadMsg] = useState('loading');
    const [isSubmit, setIsSubmit] = useState(false);

    let postData = {};

    const handleChanged = (e) => {
        const {name, value} = e.target;
        setTokenItem((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const payableNetAttr = {
        name: 'blockchain',
        label: "BLOCKCHAIN",
        color: "secondary",
        isRequired: true,
        handleChange: (e) => {
            setTokenItem((prevState) => ({
                ...prevState,
                blockchain: e.value
            }));
        }
    };

    const showSnackMessage = (msg, variant) => {
        enqueueSnackbar(msg, {variant});
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSubmit(true);
    }

    const handleAcceptSubmit = async () => {
        setIsSubmit(false);

        setIsCreatingToken(true);
        await handleCreateToken();
    }

    const handleCreateToken = async() => {
        setLoadMsg('Uploading image to the IPFS');
        let imageURL = '';
        try {
            if (image && image !== null) {
                const IpfsHash = await pinFileToIPFS(image);
                imageURL = `${PINATA_ASSET_BASE_URL}/${IpfsHash}`;
            }
            try {
                const reqData = {
                    ...tokenItem,
                    image: imageURL,
                };

                const result = await handleMutation(reqData);
                if (!!result?.data?.createCrypto?.status ) {
                showSnackMessage(t('MSG_SAVE_ASSET_SUCCESS'), 'success');
                setTimeout(() => {
                    navigate(`/deposit`);
                }, 1000);
                } else {
                showSnackMessage(result?.data?.createCrypto?.message, 'error');
                }
                setIsCreatingToken(false);
            } catch(e) {
                console.log(e);
                showSnackMessage(t('MSG_SAVE_ASSET_ISSUE'), 'error');
                setIsCreatingToken(false);
                return 0;
            }
        } catch(e) {
            console.log(e);
            setIsCreatingToken(false);
            showSnackMessage(t('MSG_UPLOAD_IPFS_IMAGE_ISSUE'), 'error');
            return 0;
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
      <>
        <Loader
          isLoading={isCreatingToken}
          message={loadMsg}
          darkMode={darkMode}
        />
  
        <AlertDialog
          open={isSubmit}
          handleClose={() => setIsSubmit(false)}
          handleAgree={handleAcceptSubmit}
          darkMode={darkMode}
          title={'ALERT_TITLE'}
          content={'ASK_CREATE_TOKEN'}
        />
        {!isMobile ? (
          <Box zIndex={1000}>
            <Box sx={{ mt: 10.5, mb: 4 }}>
            </Box>
            <Box
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
                pr: 6,
                pl: 4,
                py: 4,
                borderRadius: "16px",
              }}
            >
              <Box sx={{ mt: 5 }}>
                <Grid
                  zIndex={1000}
                  container
                  columns={{ md: 12 }}
                  spacing={{ md: 15 }}
                >
                  <Grid item md={12}>
                    <UploadComponent darkMode={darkMode} setUploadImage={setImage} label="UPLOAD_MULTIMEDIA_LABEL" description="UPLOAD_MULTIMEDIA_LABEL_DESCRIPTION"/>
                    <Box>
                      <InputComponent
                        darkMode={darkMode}
                        label="TOKEN_NAME"
                        name="name"
                        isRequired={true}
                        handleChange={handleChanged}
                        defaultValue={postData.name}
                      />
                      <Stack direction="column">
                        <SelectComponent attr={payableNetAttr} data={PAYABLE_CHAIN}/>
                      </Stack>
                      <InputComponent
                        darkMode={darkMode}
                        label="TOKEN_ADDRESS"
                        type="text"
                        handleChange={handleChanged}
                        name="contract_address"
                        defaultValue={postData.link}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <GradientButtonPrimary
                  type="submit"
                  sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                  onClick ={handleSubmit}
                >
                  <Typography component="span" color="#ffffff">
                    <MdAddToPhotos />
                  </Typography>
                  <Typography variant="body2" component="span">
                    {t("CREATE_TOKEN")}
                  </Typography>
                </GradientButtonPrimary>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "fixed",
                  top: "0%",
                  left: "44%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "10000",
                  mt: 6,
                }}
              >
                <Box
                  pb={2}
                  ml={7}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  <Typography variant="subtitle1" color="secondary" mt={1.2}>
                    <MdAddToPhotos fontSize={20} />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    component="div"
                    sx={{
                      borderBottom: `${
                        darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                      }`,
                    }}
                  >
                    {t("CREATE_TOKEN")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: 1,
                py: 2,
                borderRadius: "16px",
              }}
            >
              <Box >
                <Box>
                  <UploadComponent image={postData?.image} darkMode={darkMode} setUploadImage={setImage} label="UPLOAD_MULTIMEDIA_LABEL" description="UPLOAD_MULTIMEDIA_LABEL_DESCRIPTION"/>
                </Box>
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <InputComponent
                    darkMode={darkMode}
                    label="TOKEN_NAME"
                    name="name"
                    isRequired={true}
                    handleChange={handleChanged}
                    defaultValue={postData.name}
                  />
                </Stack>
                <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                  <InputComponent
                    darkMode={darkMode}
                    label="TOKEN_NAME"
                    name="name"
                    isRequired={true}
                    handleChange={handleChanged}
                    defaultValue={postData.name}
                  />
                </Stack>
                <Stack direction="column">
                    <SelectComponent attr={payableNetAttr} data={PAYABLE_CHAIN}/>
                </Stack>
                <Stack direction="column" spacing={2} sx={{mt:3}}>
                  <InputComponent
                    darkMode={darkMode}
                    label="TOKEN_ADDRESS"
                    type="text"
                    handleChange={handleChanged}
                    name="contract_address"
                  />
                </Stack>
                <GradientButtonPrimary
                  type="submit"
                  sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                  onClick ={handleSubmit}
                >
                  <Typography component="span" color="secondary">
                    <MdAddToPhotos />
                  </Typography>
                  <Typography variant="body2" component="span">
                    {t("CREATE_TOKEN")}
                  </Typography>
                </GradientButtonPrimary>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };
  
  export default CreateCryptos;
  