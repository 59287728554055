import React, { useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { BiMenuAltLeft } from 'react-icons/bi';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import styles from 'components/CreateCollection/CreateCollection.module.css';
import { TiTimes } from 'react-icons/ti';
import { ImImage } from 'react-icons/im';
import { FiSave } from 'react-icons/fi';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { GradientButtonPrimary } from 'Utils/GradientButtons/GradientButtons';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader/Loader';

const Input = styled('input')({
  display: 'none',
});

const CreateCollection = ({ open, handleClose, darkMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState('');
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const handleImageUpload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleCloseWithAnimate = () => {
    setIsLoading(true);
    setLoaderMessage('Loading...');
    setTimeout(function () {
      setIsLoading(false);
    }, 1000);
    handleClose();
  };
  return (
    <>
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      <Modal
        sx={{ zIndex: 500000 }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <Box
          color={darkMode ? '#fff' : '#121212'}
          bgcolor={darkMode ? '#171c26' : '#fbfbfb'}
          className={styles.modalStyle}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography
              component='div'
              display='flex'
              alignItems='center'
              gap={2}
            >
              <Typography component='span' color='secondary' mt={1}>
                <BiMenuAltLeft fontSize={'1.5rem'} />
              </Typography>
              <Typography variant='h6' component='span' fontWeight={500}>
                {t('CREATE_COLLECTION')}
              </Typography>
              <Divider sx={{ backgroundColor: '#fff' }} />
            </Typography>
          </Stack>
          <Divider />

          <Box>
            <Box>
              <Box>
                {!image ? (
                  <Box
                    sx={{
                      my: 2,
                      border: '2px solid #c4c4c4',
                      borderStyle: 'dashed',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '4px',
                      p: 3,
                      height: '150px',
                    }}
                  >
                    <label htmlFor='icon-button-file-front'>
                      <Input
                        accept='image/*'
                        id='icon-button-file-front'
                        type='file'
                        onChange={handleImageUpload}
                        required
                      />
                      <IconButton
                        color='primary'
                        aria-label='upload picture'
                        component='span'
                      >
                        <Typography
                          component='span'
                          color='secondary'
                          fontSize={30}
                        >
                          <ImImage />
                        </Typography>
                      </IconButton>
                    </label>
                  </Box>
                ) : (
                  <Box sx={{ my: 2, position: 'relative' }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '1%',
                        top: '4%',
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: `${
                            darkMode ? '#fff2f8' : '#171c26'
                          }`,
                          '&:hover': {
                            backgroundColor: `${
                              darkMode ? '#fff2f8' : '#171c26'
                            }`,
                            opacity: '0.8',
                          },
                        }}
                        onClick={() => {
                          setImage(null);
                          setFile(null);
                        }}
                      >
                        <TiTimes
                          fontSize={'1rem'}
                          color={darkMode ? '#171c26' : '#ffffff'}
                        />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        border: '1px solid #c4c4c4',
                        borderStyle: 'dashed',
                        padding: '5px',
                      }}
                    >
                      <img
                        style={{ height: '150px', width: '100%' }}
                        src={image}
                        alt='Uploaded'
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box></Box>
            <Box mt={4}>
              <Typography mb={2} variant='subtitle1'>
                {t('TITLE')}
              </Typography>
              <input
                type='text'
                style={{
                  fontSize: '14px',
                  border: '1px solid #c4c4c4',
                  borderRadius: '6px',
                  padding: '1rem 1.5rem',
                  color: `${darkMode ? '#ffffff' : '#121212'}`,
                  backgroundColor: `${darkMode ? '#171c26' : '#ffffff'}`,
                  width: '84%',
                }}
              />
            </Box>
          </Box>
          <Box className={styles.buttonBox} mt={3}>
            <Button
              onClick={() => {
                setFile(null);
                setImage(null);
                handleClose();
              }}
              variant='outlined'
              color='pink'
              className={styles.buttonStyles}
            >
              <Typography component='span' mt={1}>
                <AiOutlineCloseSquare color='#E552FF' />
              </Typography>
              <Typography variant='body2' component='span' color='#e552ff'>
                {t('CLOSE')}
              </Typography>
            </Button>
            <GradientButtonPrimary
              type='submit'
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
              }}
              onClick={() => {
                handleCloseWithAnimate();
              }}
            >
              <Typography component='span' color='#ffffff'>
                <FiSave />
              </Typography>
              <Typography
                variant='body2'
                component='span'
                sx={{
                  textTransform: 'capitalize',
                  color: '#ffffff',
                }}
              >
                {t('SAVE')}
              </Typography>
            </GradientButtonPrimary>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateCollection;
