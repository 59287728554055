import PolygonIcon from "../assets/blockchain/polygon.svg";
import BnbIcon from "../assets/blockchain/bnb.svg";
import EthereumIcon from "../assets/blockchain/ethereum.svg";
import DefaultAvatar from "assets/avatar.png";
import { polygon } from "viem/chains";

const IS_LIVE_AUCTION = "live";
const IS_UPCOMING_AUCITON = "upcoming";
const IS_FIXED_AUCTION = "fixed";

const ARR_AUCTION_TYPE = {
  [IS_LIVE_AUCTION]: 1,
  [IS_UPCOMING_AUCITON]: 2,
  [IS_FIXED_AUCTION]: 3,
};

export const PAYABLE_CHAIN = [
  {
    id: 1,
    value: "bsc",
    name: "BINANCE",
  },
  {
    id: 2,
    value: "ethereum",
    name: "ETHEREUM",
  },
  {
    id: 3,
    value: "tron",
    name: "TRON",
  },
];

export const PAYABLE_AMOUNT = [50, 100, 500, 1000, 2500, 5000, 7500, 10000];
export const TRANSACTION_EXPIRATION_TIME = {
  minutes: 30,
  seconds: 0,
};

export const defaultPngAvatar = DefaultAvatar;
export const EXPLORE_FIELD = "explore";
export const AUCTION_FIELD = "auction";
export const LIMIT_SETTING = 12;
export const PINATA_API_KEY = "6c5cebf0e3de470130c3";
export const PINATA_ASSET_BASE_URL = "https://nftrewards.mypinata.cloud/ipfs";
export const PINATA_SECRET_API_KEY =
  "5f8da254819a8cca208b845522aa1330a5c23776482656714a6d100f4481d9cc";
// export const MAINTENANCE_URL = 'https://nftrmarketplace.com/noResponse';
export const MAINTENANCE_URL = "http://localhost:3000/noResponse";

export const wbnbAbi = require("./abi/WBNB.json");
export const wmaticAbi = require("./abi/WMATIC.json");
export const wethAbi = require("./abi/WETH.json");

export const ethereumContract = "0x55Eb30D379392Bdd1d45f2F035fd28a57B2B6975"; /// mainnet
export const binanceContract = "0xbb094d9186956d0d0861fd64794b8d7e494564f6"; /// Mainnet
// export const binanceContract = "0xf68F24237b47600b620ED08C1E107a460e4fed8c"; /// test net
export const polygonContract = "0xC0aAD2Dc5b088fb716279Cf5b935cf60c13E0799"; ///mainnet
// export const polygonContract = "0xD6c495a9a193FE6Acd52174385a67BAF66525500"; ///testnet

export const wmaticContractAddress =
  "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"; ///wmatic testnet

export const wbnbContractAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const wethContractAddress = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";

export const ethereumMainNetInfo = {
  chainId: 1,
  chainName: "Ethereum Mainnet",
  blockExplorer: "https://etherscan.io/",
  rpcUrl: "https://mainnet.infura.io/v3/",
};

export const binanceMainNetInfo = {
  chainId: 56,
  chainName: "Binance Mainnet",
  blockExplorer: "https://bscscan.com/",
  rpcUrl: "https://bsc-mainnet.public.blastapi.io",
};

export const polygonTestnetInfo = {
  chainId: 80001,
  chainName: "Polygon Testnet",
  blockExplorer: "https://mumbai.polygonscan.com/",
  rpcUrl: "https://rpc-mumbai.maticvigil.com",
};

export const polygonMainnetInfo = {
  chainId: 137,
  chainName: "Polygon Mainnet",
  blockExplorer: "https://polygonscan.com/",
  rpcUrl: "https://polygon-mainnet.infura.io",
};

export const chainName = {
  56: "Binance Mainnet",
  1: "Ethereum Mainnet",
  80001: "Polygon Testnet",
  137: "Polygon Mainnet",
};
export const ETH_CURRENT_NET = ethereumMainNetInfo;
export const BINANCE_CURRENT_NET = binanceMainNetInfo;
export const POLYGON_CURRENT_NET = polygonMainnetInfo;
// export const POLYGON_CURRENT_NET = polygonTestnetInfo;

export const pinata_api_key = "6c5cebf0e3de470130c3"; /// ???Pinata
export const pinata_asset_base_url = "https://nftrewards.mypinata.cloud/ipfs"; ///????Pinata
export const pinata_secret_api_key =
  "5f8da254819a8cca208b845522aa1330a5c23776482656714a6d100f4481d9cc"; ///???Pinata

export const backend_base_url = "https://auction.nftrmarketplace.com/";
export const backend_graphql_url =
  "https://auction.nftrmarketplace.com/graphql";
export const backend_graphql_subscription_url =
  "wss://auction.nftrmarketplace.com/graphql";
export const nftr_base_url = "https://nftglobal.pro/";

// export const backend_base_url = "http://localhost:5000/";
// export const backend_graphql_url = "http://localhost:5000/graphql";
// export const backend_graphql_subscription_url = "ws://localhost:5000/graphql";
// export const nftr_base_url = "http://localhost:8000/";

export const DOC_URL = "https://docs.nftreward.io";
export const HELP_URL = "https://hc.nftreward.io";
export const BASE_URL = "https://nftrmarketplace.com/";
export const currentMaticPrice = 0.5;

export const SellMethod = {
  Highest: 0,
  Declining: 1,
};

export const Status = {
  Creator: 0,
  Onsell: 1,
  Buy: 2,
  Sell: 3,
  Transfer: 4,
  Offer: 5,
  CancelOffer: 6,
  CancelList: 7,
};

export const MaxUint256 =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const TIME_SLOT_INTERVAL = 15;

export const SellType = {
  NO_SELL: "no-sell",
  FIXED: "fixed",
  TIMED: "timed",
};

export const Chains = [
  {
    logo: "https://polygonscan.com/assets/poly/images/svg/logos/polygon-chain.svg",
    local: PolygonIcon,
    display: "Polygon",
    value: "Polygon",
  },
  {
    logo: "https://bscscan.com//assets/bsc/images/svg/logos/bnb-chain.svg",
    local: BnbIcon,
    display: "Binance",
    value: "Binance",
  },
  {
    logo: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    local: EthereumIcon,
    display: "Ethereum",
    value: "Ethereum",
  },
];

export const chainIcons = {
  Polygon: {
    logo: "https://polygonscan.com/assets/poly/images/svg/logos/polygon-chain.svg",
    local: PolygonIcon,
  },
  Binance: {
    logo: "https://bscscan.com//assets/bsc/images/svg/logos/bnb-chain.svg",
    local: BnbIcon,
  },
  Ethereum: {
    logo: "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    local: EthereumIcon,
  },
};

export const defaultFilterValues = {
  blockchain: {
    value: "Polygon",
    display: "Polygon",
  },
  sort: {
    value: "created_at",
    display: "DATE_CREATED",
  },
  order: {
    value: "DESC",
    display: "NEWEST",
  },
};

export const sort = [
  {
    value: "created_at",
    display: "DATE_CREATED",
  },
  {
    value: "listing_count",
    display: "POPULARITY",
  },
];

export const order = {
  created_at: [
    {
      value: "DESC",
      display: "NEWEST",
    },
    {
      value: "ASC",
      display: "OLDEST",
    },
  ],
  listing_count: [
    {
      value: "DESC",
      display: "BIGGEST",
    },
    {
      value: "ASC",
      display: "SMALLEST",
    },
  ],
};

export const getAuctionStatus = (type) => {
  return ARR_AUCTION_TYPE[type]
    ? ARR_AUCTION_TYPE[type]
    : ARR_AUCTION_TYPE[IS_LIVE_AUCTION];
};

export const InfoURL = {
  POLYGON_URL: "https://polygonscan.com/nft/",
  // POLYGON_URL: 'https://mumbai.polygonscan.com/nft/', //testnet
  PINATA_GATEWAY: "https://nftrewards.mypinata.cloud/ipfs/",
  NFTR_BASE_URL: "https://nftglobal.pro/",
  // NFTR_BASE_URL: 'http://localhost:8000/'
};

export const AuctionStatus = {
  NO_AUCTION: "no-auction",
  LISTING: "listed",
  ON_AUCTION: "on-auction",
  AUCTION_FINISHED: "auction-finished",
  AUCTION_UPCOMING: "auction-upcoming",
  AUCTION_CLAIMED: "auction-claimed",
  AUCITON_COLLECTED: "auction-collected",
};

export const SellStatus = {
  CREATED: "created",
  ON_SELL: "on-sell",
  PURCHASED: "purchased",
  CANCELLED: "cancelled",
  LOWER_PRICED: "lowerPriced",
};

export const googleConfig = {
  clientID:
    "11022736622-iq6roh1tvb96bi2rnt9tkobk8acj75jf.apps.googleusercontent.com",
  project_id: "nftr-project",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_secret: "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",
};

export const categories = [
  {
    id: 1,
    text: "All",
    path: "?type=all",
    type: "all",
  },
  {
    id: 2,
    text: "FILTER_TAB_ART",
    path: "?type=art",
    type: "art",
  },
  {
    id: 3,
    text: "FILTER_TAB_MUSIC",
    path: "?type=music",
    type: "music",
  },
  {
    id: 4,
    text: "FILTER_TAB_POSTER",
    path: "?type=poster",
    type: "poster",
  },
  {
    id: 5,
    text: "FILTER_TAB_SIGNATURE",
    path: "?type=signature",
    type: "signature",
  },
  {
    id: 6,
    text: "FILTER_TAB_MEMES",
    path: "?type=memes",
    type: "memes",
  },
];

export const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3.2,
      },
    },
    {
      breakpoint: 966,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 2.2,
        initialSlide: 2.2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export const COLUMNS = [
  { field: "id", headerName: "id", width: 90 },
  { field: "collection_id", headerName: "collection ID", width: 150 },
  { field: "token_id", headerName: "token ID", width: 100 },
  { field: "name", headerName: "name", width: 200 },
  { field: "auction_status", headerName: "status", width: 150, editable: true },
  { field: "last_price", headerName: "price", width: 100 },
  { field: "owner", headerName: "owner", width: 350 },
  { field: "auction_winner", headerName: "winner", width: 350 },
  { field: "blockchain_owner", headerName: "blockchain owner", width: 350 },
  { field: "auction_end_at", headerName: "end time", width: 250 },
];

export const liveAuctionSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 966,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 2.2,
        initialSlide: 2.2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export const chainInfo = {
  Polygon: { name: "Polygon", id: "matic-network", symbol: "MATIC" },
  Ethereum: { name: "Ethereum", id: "ethereum", symbol: "ETH" },
  Binance: {
    name: "Binance",
    id: "binancecoin",
    symbol: "BNB",
  },
};

export const companyWallet = "0x733eBfB0E40e36F8FCC1022B9742A6223e9AfCAF";
